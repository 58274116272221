* {
  box-sizing: border-box;
}
#root,
body,
html {
  height: 100%;
  background: linear-gradient(to bottom right, #aed8e6, #b7e0a9, #ffcc99);
}
.app-container {
  height: 100%;
  nav {
    backdrop-filter: saturate(0.5);
  }
}
.chat-window {
  background: linear-gradient(to bottom right, #aed8e6, #b7e0a9, #ffcc99);
  height: 100%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.chat-window__messages {
  flex-grow: 1;
  overflow-y: scroll;
  padding: 0;
  margin: 0;
  list-style: none;
  padding-bottom: 10rem;

  li {
    margin: 1rem;
    padding: 0.5rem;
    background-color: #f1f0f0;
    border-radius: 10px;
    white-space: pre-wrap;
    line-height: 30px;
    display: flex;

    &:nth-child(odd) {
      background-color: rgb(235, 255, 235);
    }

    i {
      line-height: 30px;
      font-size: 30px;
    }
  }
}
.chat-window__wrapper-form {
  position: fixed;
  bottom: 2rem;
  width: 80%;
  padding: 1rem 0rem 1rem;
  margin-top: 1rem;
  left: 50%;
  transform: translate(-50%);
}
.chat-window__input-form {
  display: flex;
  box-sizing: border-box;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
}

.chat-window__input-form__input {
  flex-grow: 1;
  margin-right: 1rem;
  padding: 0.5rem;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  font-size: 1.1rem;

  @media (max-width: 768px) {
    margin: 0;
    margin-bottom: 1rem;
  }
}

.chat-window__input-form__submit-button {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  border: none;
  // background-color: #3a3c3f;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  // color: white;
  font-size: 1.1rem;
  cursor: pointer;

  &:hover {
    background-color: #515559;
  }

  @media (max-width: 768px) {
    margin: 0;
  }
}
.avatar {
  width: 33px;
  height: 35px;
  padding: 1px;
}

.answer-org {
  background-color: #eee;
  margin-bottom: 20px;
  border-radius: 5px;
  padding-inline: 10px;
  border: 1px solid black;
  .answer-title {
    padding-block: 10px;
  }
  .answer-content {
    background-color: #dedede;
    margin: 2px;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
}
.textarea-wrapper {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin-inline: auto;
}

.textarea-wrapper .form-control {
  padding-right: 50px; /* adjust this value to fit the button */
}

.textarea-wrapper .svg-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 50px;
  width: 50px;
}
